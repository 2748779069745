function Footer(){
    return(
        <div id="layoutDefault_footer">
                <footer class="footer pt-10 pb-5 mt-auto bg-dark footer-dark">
                    <div class="container px-5">
                        <div class="row gx-5">
                            <div class="col-lg-3">
                                <div class="footer-brand">Primetaxonline.com</div>
                                <div class="icon-list-social mb-5">
                                    <a class="icon-list-social-link" href="https://www.instagram.com/primetaxonline/"><i class="fab fa-instagram"></i></a>
                                    <a class="icon-list-social-link" href="https://www.linkedin.com/company/primetaxonline-com/"><i class="fab fa-linkedin"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div class="row gx-5">
                                    <div class="col-lg-3 col-md-6 mb-5 mb-lg-0">
                                        <div class="text-uppercase-expanded text-xs mb-4">Product</div>
                                        <ul class="list-unstyled mb-0">
                                            <li class="mb-2"><a href="/services">Tax</a></li>
                                            <li class="mb-2"><a href="/services">Business</a></li>
                                            <li class="mb-2"><a href="/services">Investments</a></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-3 col-md-6 mb-5 mb-lg-0">
                                        <div class="text-uppercase-expanded text-xs mb-4">Our Offices</div>
                                        <ul class="list-unstyled mb-0">
                                        <li class="mb-2">United States: <div>100 sw 10th st, Miami, FL 33130</div></li>
                                            <li class="mb-2"><div>Argentina: <div>Dardo Rocha 1046, Office 4, San Isidro, Provincia de Buenos Aires</div></div></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <div class="text-uppercase-expanded text-xs mb-4">Legal</div>
                                        <ul class="list-unstyled mb-0">
                                            <li class="mb-2"><a href="https://primetaxonline.com/privacy_policy.html">Privacy Policy</a></li>
                                            <li class="mb-2"><a href="#!">Terms and Conditions</a></li>
                                            <li><a href="#!">License</a></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-3 col-md-6 mb-5 mb-md-0">
                                        <div class="text-uppercase-expanded text-xs mb-4">Contact</div>
                                        <ul class="list-unstyled mb-0">
                                            <li class="mb-2">info@primetaxonline.com</li>
                                            <li class="mb-2">+54 11 3655-5405</li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <hr class="my-5" />
                        <div class="row gx-5 align-items-center">
                            <div class="col-md-4 small">Copyright &copy; primetaxonline.com 2023</div>
                            <div class="col-md-4 small">Powered by <b><a href="https://brightsite.dev">brightsite.dev</a></b></div>
                            <div class="col-md-4 text-md-end small">
                                <a href="https://primetaxonline.com/privacy_policy.html">Privacy Policy</a>
                                &middot;
                                <a href="#!">Terms &amp; Conditions</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
    )
}

export default Footer;