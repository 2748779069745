import Navbar from './Navbar';
import  Footer  from './Footer';
import Wpp from './Wpp';

function Services() {
    return (
        <>
            <Navbar />
            <div id="layoutDefault">
                <div id="layoutDefault_content">
                    <main>
                        <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                            <div class="page-header-ui-content pt-10">
                                <div class="container px-5 text-center">
                                    <div class="row gx-5 justify-content-center">
                                        <div class="col-lg-8">
                                            <h1 class="page-header-ui-title mb-3">Our Services</h1>
                                            <p class="page-header-ui-text">Our product, priced to fit your specific needs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="svg-border-rounded text-light">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                            </div>
                        </header>
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Tax</h2>
                                </div>
                                <div class="row gx-5">
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                    <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Non-Resident</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            <sup>$</sup>
                                            99
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Non-resident Individual Income Tax Return (Form 1040NR)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Income wage statement (Form W-2)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Interest and dividend earnings</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Rental property</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Schedule OI </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                    </div>
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                    <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Resident</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            <sup>$</sup>
                                            299
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Resident Individual Income Tax Return (Form 1040)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Income wage statement (Form W2)</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Interest and dividend earnings</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Rental property</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Self-employment</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Charitable contributions deductions</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Maximization of mortgage and tax deductions</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Stock, bonds, ESPPs and other investments</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Claim your stimulus check</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Schedule K-1</span>
                                        </li>
                                        <li class="pricing-list-item" style={{padding:'15px'}}>
                                            <span class="text-dark">*Consult with an expert to determine your tax needs</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                    </div>
                                    <div class="col-lg-4">
                                    <div class="card pricing h-100">
                                <div class="card-body p-5">
                                    <div class="text-center">
                                        <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Expat</div>
                                        <div class="pricing-price">
                                            <div className='starts_at_price'></div>
                                            <sup>$</sup>
                                            349
                                        </div>
                                    </div>
                                    <ul class="fa-ul pricing-list">
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Optimization between Foreign Earned Income Exclusion and Foreign Tax Credit.</span>
                                        </li>
                                        <li class="pricing-list-item">
                                            <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                            <span class="text-dark">Foreign Bank Account to U.S Treasury (Additional $100)</span>
                                        </li>
                                        <li class="pricing-list-item" style={{padding:'15px'}}>
                                            <span class="text-dark">*Consult with an expert to determine your tax needs</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                                    </div>
                                </div>
                                <div class="mt-5 text-center"><a class="btn btn-primary fw-500" href="/appointments">Get Started</a></div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Investment</h2>
                                    <p class="lead">FREE Real estate market consulting</p>
                                </div>
                                <div class="row g-0 align-items-center">
                                    <div class="col-lg-6 mb-5 mb-lg-0">
                                        <div class="card pricing">
                                            <div class="card-body p-5">
                                                <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Free</div>
                                                <div class="pricing-price">
                                                    <sup>Consulting</sup>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Investment consulting</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Invest in the U.S Stock market</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Investment VISA advisory </span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Short, medium and long term investment plans</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Small capital investments</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Large capital investments </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="card-footer px-5">
                                                <a class="d-flex align-items-center justify-content-between" href="/appointments">
                                                    Get Started!
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card pricing pricing-right bg-gray-800">
                                            <div class="card-body p-5">
                                                <div class="badge bg-transparent-light badge-marketing rounded-pill badge-sm">Customized</div>
                                                <p class="card-text py-5">Need a customized plan? Contact us directly and we can work with you to craft a plan that fits your company's specific needs.</p>
                                            </div>
                                            <div class="card-footer bg-gray-900 px-5">
                                                <a class="text-white-50 d-flex align-items-center justify-content-between" href="/appointments">
                                                    Contact an expert
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Business</h2>
                                    <p class="lead">Easy pricing means no suprises.</p>
                                </div>
                                <div class="row g-0 align-items-center">
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                        <div class="card pricing pricing-left">
                                            <div class="card-body p-5">
                                                <div class="text-center">
                                                    <div class="badge bg-light text-dark rounded-pill badge-marketing badge-sm">Admin</div>
                                                    <div class="pricing-price">
                                                        <div className='starts_at_price'></div>
                                                        <sup>$</sup>
                                                        149
                                                        <span class="pricing-price-period">/mo</span>
                                                    </div>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Payroll</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Bookkeeping</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Web/ cloud applications development</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Marketing</span>
                                                    </li>
                                                </ul>
                                                <div style={{textAlign:'center'}}><a class="btn btn-primary fw-500 me-3 my-2" href="/appointments">Schedule a free call</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                        <div class="card pricing py-4 z-1">
                                            <div class="card-body p-5">
                                                <div class="text-center">
                                                    <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Business Tax</div>
                                                    <div class="pricing-price">
                                                        <div className='starts_at_price'></div>
                                                        <sup>$</sup>
                                                        499
                                                    </div>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">U.S Corporation Income Tax Return (Form 1120)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">U.S Return of Partnership Income (Form 1065)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">U.S Income tax return for an S-Corporation (Form 1120-S)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Optimal tax structure and organization </span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Information Return of a 25% Foreign- Owned U.S Corporation or a Foreign Corporation Engaged in a U.S Trade or Business (Form 5472)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Information Return of U.S Persons with respect to Certain Foreign Corporations (Form 5471)</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Schedule K-1</span>
                                                    </li>
                                                </ul>
                                                <div style={{textAlign:'center'}}><a class="btn btn-primary fw-500 me-3 my-2" href="/appointments">Schedule a free call</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 mb-5 mb-lg-0">
                                        <div class="card pricing pricing-right">
                                            <div class="card-body p-5">
                                                <div class="text-center">
                                                    <div class="badge bg-secondary-soft rounded-pill badge-marketing badge-sm text-secondary">LLC</div>
                                                    <div class="pricing-price">
                                                        <sup>$</sup>
                                                        1,049
                                                    </div>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Open LLC</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Open U.S bank account</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Operating agreement</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Address and representative in Wyoming or Delaware</span>
                                                    </li>
                                                </ul>
                                                <div style={{textAlign:'center'}}><a class="btn btn-primary fw-500 me-3 my-2" href="/appointments">Schedule a free call</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light py-10">
                            <div class="container px-5">
                                <div class="text-center mb-5">
                                    <h2>Technology</h2>
                                    <p class="lead">Everything your business needs in the IT world</p>
                                </div>
                                <div class="row g-0 align-items-center">
                                    <div class="col-lg-6 mb-5 mb-lg-0">
                                        <div class="card pricing">
                                            <div class="card-body p-5">
                                                <div class="badge bg-primary-soft rounded-pill badge-marketing badge-sm text-primary">Free consulting</div>
                                                <div class="pricing-price">
                                                    <sup>Our IT services</sup>
                                                </div>
                                                <ul class="fa-ul pricing-list">
                                                <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Create and register your brand and logo</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Register your domain</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Create and host your online web/application</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Database management</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">IT Support</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">E-commerce</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Cybersecurity</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Online marketing</span>
                                                    </li>
                                                    <li class="pricing-list-item">
                                                        <span class="fa-li"><i class="far fa-check-circle text-teal"></i></span>
                                                        <span class="text-dark">Email newsletter</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="card-footer px-5">
                                                <a class="d-flex align-items-center justify-content-between" href="/appointments">
                                                    Get Started!
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card pricing pricing-right bg-gray-800">
                                            <div class="card-body p-5">
                                                <div class="badge bg-transparent-light badge-marketing rounded-pill badge-sm">Need to hire IT professionals?</div>
                                                <p class="card-text py-5">We help set up teams with IT professionals to work for your business working from our office in Argentina.</p>
                                            </div>
                                            <div class="card-footer bg-gray-900 px-5">
                                                <a class="text-white-50 d-flex align-items-center justify-content-between" href="/appointments">
                                                    Contact an expert
                                                    <i class="fas fa-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr class="my-0" />
                        <section class="bg-light pt-10">
                            <div class="container px-5">
                                <div class="row gx-5 align-items-center mb-10">
                                    <div class="col-lg-6">
                                        <div class="h4 fw-normal">Get in touch</div>
                                    </div>
                                    <div class="col-lg-6 text-lg-end">
                                        <a class="btn btn-primary fw-500 me-3 my-2" href="/contact">Contact Sales</a>
                                        <a class="btn btn-white fw-500 shadow my-2" href="/appointments">Schedule a free call</a>
                                    </div>
                                </div>
                                <div class="row gx-5">
                                    <div class="col-lg-6 z-1 mb-5 mb-lg-n5">
                                        <a class="card text-decoration-none h-100 lift" href="#!">
                                            <div class="card-body py-5">
                                                <div class="d-flex align-items-center">
                                                    <div class="ms-4">
                                                        <h5 class="text-primary">Work smarter, not harder</h5>
                                                        <p class="card-text text-gray-600">Learn more about how our product can save you time and effort in the long run!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-6 z-1 mb-10 mb-lg-n5">
                                        <a class="card text-decoration-none h-100 lift" href="#!">
                                            <div class="card-body py-5">
                                                <div class="d-flex align-items-center">
                                                    <div class="ms-4">
                                                        <h5 class="text-secondary">Tailored just for you</h5>
                                                        <p class="card-text text-gray-600">Let us know precisely what you need and we’ll design a solution just for you.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="svg-border-waves text-dark">

                            </div>
                        </section>
                    </main>
                </div>
                <Wpp />
                <Footer />
            </div>
        </>
    )
}

export default Services;