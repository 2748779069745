import  Navbar  from './NavbarES';
import  Footer  from './FooterES';
import { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner'
import axios from 'axios';
import imgBusiness from '../../img/illustrations/business_img.jpg';
import usa_flag from '../../img/illustrations/flags/usa_flag.png';
import chile_flag from '../../img/illustrations/flags/chile_flag.png';
import arg_flag from '../../img/illustrations/flags/arg_flag.png';
import mx_flag from '../../img/illustrations/flags/mx_flag.png';
import Wpp from '../Wpp';


function CommentsES(){

    return(
        <>
        <Navbar />
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div class="page-header-ui-content pt-10">
                            <div class="container px-5 text-center">
                                <div class="row gx-5 justify-content-center">
                                    <div class="col-lg-8">
                                        <h1 class="page-header-ui-title mb-3">Comentarios</h1>
                                        <p class="page-header-ui-text">Lo que algunos de nuestros clientes tienen para decir!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="svg-border-rounded text-light">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section class="bg-light py-10">
                        <div class="container px-5">
                        <div class="row gx-5 justify-content-center">
                                <div class="col-lg-8 text-center">
                                    <h2>Basado en experiencias de nuestros clientes</h2>
                                    <p class="lead mb-5"></p>
                                </div>
                            </div>
                    <section class="">
                <div class="container px-5">
                    <div className='row'>
                        <div className="col-lg-6" style={{ zIndex: '1', padding:'5px' , verticalAlign: 'middle' , justifyContent:'center'}}>
                            <a class="card text-decoration-none h-100 lift" href="#!" style={{verticalAlign: 'middle' }}>
                            <div class="card-body py-5">
                                    <div class="align-items-center" style={{textAlign:'center'}}>
                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${arg_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                        <div class="ms-4" style={{padding:'10px', textAlign:'left'}}>
                                            <h5 class="text-primary">Alex B. – Americano viviendo en Argentina</h5>
                                            <p class="card-text text-gray-600" style={{ overflowY: "auto" }}>"Después de muchos años de estrés sobre mis impuestos de los EE.UU mientras vivía en Argentina, finalmente encontré a primetaxonline.com; que me ayudaron a navegar todos los requerimientos y poner mis asuntos en orden. Todo por un precio razonable. Gracias por ayudar a reducir mi nivel de ansiedad!!!"</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6" style={{ zIndex: '1' }}>
                            <div className='row'>
                                <div className='col-lg-12' style={{padding:'5px'}}> 
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${usa_flag})`, backgroundSize: 'cover'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Nicolas C. – Ciudadano Americano viviendo en los Estados Unidos</h5>
                                                            <p class="card-text text-gray-600" style={{}}>"Proceso muy simple. Fueron muy informativos y me ayudaron en cada paso."</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                </div>
                                <div className='col-lg-12' style={{padding:'5px'}}>
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                    <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${chile_flag})`, backgroundSize: 'cover'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Barbara S. – Ciudadana americana viviendo en Chile.</h5>
                                                            <p class="card-text text-gray-600" style={{overflowY:"auto"}}>"Vengo haciendo mis impuestos con ellos por muchos años. Altamente recomendado."</p>
                                                        </div>
                                                    </div>
                                                </div>
                                </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12" style={{ zIndex: '1' }}>
                            <div className='row'>
                                <div className='col-lg-12' style={{padding:'5px'}}> 
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${arg_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Francisco A. – Ciudadano Argentino viviendo en Argnetina</h5>
                                                            <p class="card-text text-gray-600" style={{}}>"Sinceramente Gonzalo brinda un excelente servicio y considero que es un gran    profesional. A comienzos del 2023 decidí abrir mi empresa en Estados Unidos y me acompañó durante todo el proceso. Súper rápido y muy claro."</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                </div>
                                <div className='col-lg-12' style={{padding:'5px'}}>
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                    <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${mx_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">David G. – Ciudadano Americano viviendo en Mexico</h5>
                                                            <p class="card-text text-gray-600" style={{overflowY:"auto"}}>"Como ciudadano dual, mexicano y estadounidense, viviendo en México, Gonzalo Serravalle ha sido sumamente útil, guiándome en cada paso para resolver una situación fiscal complicada. ¡No puedo recomendarlo lo suficiente! Vivir como expatriado ya es lo suficientemente difícil, por lo que es genial contar con ayuda en estas situaciones delicadas."</p>
                                                        </div>
                                                    </div>
                                                </div>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
                        </div>
                        <div class="svg-border-rounded text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </section>
                </main>
            </div>
            <Wpp />
            <Footer />
        </div>
        </>
    )
}

export default CommentsES;