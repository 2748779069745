import  Navbar  from './Navbar';
import  Footer  from './Footer';
import { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner'
import axios from 'axios';
import Wpp from './Wpp';
import imgBusiness from '../img/illustrations/business_img.jpg';
import usa_flag from '../img/illustrations/flags/usa_flag.png';
import chile_flag from '../img/illustrations/flags/chile_flag.png';
import arg_flag from '../img/illustrations/flags/arg_flag.png';
import mx_flag from '../img/illustrations/flags/mx_flag.png';


function Comments(){

    return(
        <>
        <Navbar />
        <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div class="page-header-ui-content pt-10">
                            <div class="container px-5 text-center">
                                <div class="row gx-5 justify-content-center">
                                    <div class="col-lg-8">
                                        <h1 class="page-header-ui-title mb-3">Comments</h1>
                                        <p class="page-header-ui-text">See what some of out clients have to say!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="svg-border-rounded text-light">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section class="bg-light py-10">
                        <div class="container px-5">
                        <div class="row gx-5 justify-content-center">
                                <div class="col-lg-8 text-center">
                                    <h2>Based on our clients experiences</h2>
                                    <p class="lead mb-5"></p>
                                </div>
                            </div>
                    <section class="">
                <div class="container px-5">
                    <div className='row'>
                        <div className="col-lg-6" style={{ zIndex: '1', padding:'5px' , verticalAlign: 'middle' , justifyContent:'center'}}>
                            <a class="card text-decoration-none h-100 lift" href="#!" style={{verticalAlign: 'middle' }}>
                            <div class="card-body py-5">
                                    <div class="align-items-center" style={{textAlign:'center'}}>
                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${arg_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                        <div class="ms-4" style={{padding:'10px', textAlign:'left'}}>
                                            <h5 class="text-primary">Alex B. - American Expat living in Argentina.</h5>
                                            <p class="card-text text-gray-600" style={{ overflowY: "auto" }}>"After many years of stressing over my U.S. taxes while living in Argentina, I finally found Primetaxonline who quickly and painlessly helped me navigate all the requirements and get all my taxes in order quickly. All for a reasonable fee. Thanks for helping reduce my level of anxiety!!!"</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-6" style={{ zIndex: '1' }}>
                            <div className='row'>
                                <div className='col-lg-12' style={{padding:'5px'}}> 
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${usa_flag})`, backgroundSize: 'cover'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Nicolas C. - American Citizen living in the U.S.</h5>
                                                            <p class="card-text text-gray-600" style={{}}>"Very simple process. They were very informative and helped every step along the way"</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                </div>
                                <div className='col-lg-12' style={{padding:'5px'}}>
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                    <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${chile_flag})`, backgroundSize: 'cover'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Barbara S. - American Expat living in Chile.</h5>
                                                            <p class="card-text text-gray-600" style={{overflowY:"auto"}}>"I have been doing my taxes with them for years. Highly recommended."</p>
                                                        </div>
                                                    </div>
                                                </div>
                                </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12" style={{ zIndex: '1' }}>
                            <div className='row'>
                                <div className='col-lg-12' style={{padding:'5px'}}> 
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                        <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${arg_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">Francisco A. – American Expat living in Argentina.</h5>
                                                            <p class="card-text text-gray-600" style={{}}>"Sincerely Gonzalo provides an excellent service and I consider he is a great professional. At the start of 2023 I decided to open my company in the United States, and he accompanied me throughout the entire process. Super-fast and very clear"</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                </div>
                                <div className='col-lg-12' style={{padding:'5px'}}>
                                <a class="card text-decoration-none h-100 lift" href="#!">
                                                <div class="card-body py-5">
                                                    <div class="d-flex align-items-center">
                                                    <div class="icon-stack icon-stack-xl bg-primary text-white flex-shrink-0" style={{backgroundImage: `url(${mx_flag})`, backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
                                                        <div class="ms-4">
                                                            <h5 class="text-primary">David G. – American citizen living in Mexico</h5>
                                                            <p class="card-text text-gray-600" style={{overflowY:"auto"}}>"As a dual, Mexican and American citizen living in Mexico, Gonzalo Serravalle has been extremely helpful, guiding me every step of the way towards setting a complicated tax situation in order. I can't recommend him highly enough! Living as an expat is difficult enough. It is great to have help in these delicate situations."</p>
                                                        </div>
                                                    </div>
                                                </div>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
                        </div>
                        <div class="svg-border-rounded text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </section>
                </main>
            </div>
            <Wpp />
            <Footer />
        </div>
        </>
    )
}

export default Comments;