import  Navbar  from './Navbar';
import  Footer  from './Footer';
import { Carousel } from 'react-bootstrap';
import gonzalo from '../img/illustrations/profiles/gjs_r_2.jpg';
import cameron from '../img/illustrations/profiles/cameron_4.jpg';
import sjs from '../img/illustrations/profiles/sjs_2.jpg';
import jls from '../img/illustrations/profiles/jls_2.jpg';
import jp from '../img/illustrations/profiles/jp.jpg';
import tomas from '../img/illustrations/profiles/tomas_2.jpg';
import nicolas from '../img/illustrations/profiles/nicolas_2.jpg';
import team_1 from '../img/illustrations/profiles/team_1.jpg';
import team_2 from '../img/illustrations/profiles/team_2.jpg';
import team_3 from '../img/illustrations/profiles/team_3.jpg';
import team_4 from '../img/illustrations/profiles/team_4.jpg';
import brightsite from '../img/logos/brightsite_logo_r.png';

function Team(){
    return(
    <>
    <h1 class="mb-4" style={{textAlign:'center',padding:'50px'}}>Meet the team</h1>
                        <div class="container px-5">
                            <div class="row gx-5">
                                <div class="col-md-6 col-xl-4 mb-5">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={gonzalo} alt="..." />
                                            <div class="card-team-name">Gonzalo Serravalle</div>
                                            <div class="card-team-position mb-3">Chief Executive Officer (CEO)</div>
                                            <p class="small mb-0">Specializes in personal income taxation. Navigating international tax matters can be a tricky task. Gonzalo has helped hundreds of clients overseas, and locally, find the optimal path to being in compliance with U.S tax authorities (IRS & US Treasury) - while getting the best tax benefits. His main goal is that the client can understand the process throughout the entire way and can get rid of any tax burden he or she may be under.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={sjs} alt="..." />
                                            <div class="card-team-name">Santiago Serravalle</div>
                                            <div class="card-team-position mb-3">Chief Financial Officer (CFO)</div>
                                            <p class="small mb-0">Responsible for strategic financial management and outsourcing initiatives, ensuring optimal resource allocation and cost efficiency to drive company growth and profitability.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={jls} alt="..." />
                                            <div class="card-team-name">Jose Serravalle</div>
                                            <div class="card-team-position mb-3">Chief Operating Officer (COO)</div>
                                            <p class="small mb-0">Overseeing the development and management of strategic alliances and partnerships, driving synergies and growth opportunities to enhance operational effectiveness and market positioning.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                             <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5 mb-xl-0">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={jp} alt="..." />
                                            <div class="card-team-name">Juan P.</div>
                                            <div class="card-team-position mb-3">Cloud Engineer</div>
                                            <p class="small mb-0">Juan is a very dedicated IT professional specialized in cloud architecture and servers.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                        <a href='https://brightsite.dev'><img src={brightsite} /></a> <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4 mb-5 mb-md-0">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={tomas} alt="..." />
                                            <div class="card-team-name">Tomas F.</div>
                                            <div class="card-team-position mb-3">Head of Investments</div>
                                            <p class="small mb-0">If you are looking to invest in the United State real estate and/ or stock markets; or simply micromanage your finances - Tomas will make sure your money is exercising the best way possible and, along with his team, will tailor a short, medium or long term plan that is right for you.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xl-4">
                                    <div class="card card-team">
                                        <div class="card-body">
                                            <img class="card-team-img mb-3" src={nicolas} alt="..." />
                                            <div class="card-team-name">Nicolas M.</div>
                                            <div class="card-team-position mb-3">Commercial</div>
                                            <p class="small mb-0">Client satisfaction, communications and marketing strategies are just the tip of the iceberg for Nicolas’ skills. In charge of social media platforms, news articles and analytics, he leads communications to make sure all of our pillars come together harmoniously.</p>
                                        </div>
                                        <div class="card-footer text-center">
                                            <a class="btn btn-icon btn-transparent-dark mx-1" href="#!"><i class="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className='row' style={{padding:'40px'}}>
                                    <div className='col-lg-6 col-md-12 col-sm-12' style={{paddingTop:'5px'}}>
                                        <Carousel fade={true} controls={false} indicators={false}>
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_1} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_2} />
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                    <div className='col-lg-6 col-lg-6 col-md-12 col-sm-12' style={{paddingTop:'5px'}}>
                                        <Carousel fade={true} controls={false} indicators={false}>
                                            
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_3} />
                                            </Carousel.Item>
                                            <Carousel.Item>
                                                <img style={{objectFit: 'cover',width:'100%'}} src={team_4} />
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                </div>
                        </div>
    </>
        
    )
}

export default Team;