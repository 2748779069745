import  Navbar  from './NavbarES';
import  Footer  from './FooterES';
import  Team  from './TeamES';
import Wpp from '../Wpp';

function About(){
    return(
<>
    <Navbar />
    <div id="layoutDefault">
            <div id="layoutDefault_content">
                <main>
                    
                    <header class="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
                        <div class="page-header-ui-content pt-10">
                            <div class="container px-5 text-center">
                                <div class="row gx-5 justify-content-center">
                                    <div class="col-lg-8">
                                        <h1 class="page-header-ui-title mb-3">Acerca de nosotros</h1>
                                        <p class="page-header-ui-text">Nuestra cultura y como hacemos lo que amamos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="svg-border-rounded text-white">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </header>
                    <section class="bg-white py-10">
                        <div class="container px-5">
                            <div class="row gx-5 justify-content-center">
                                <div class="col-lg-10">
                                    <h2 class="mb-4">Quienes somos</h2>
                                    <p>Somos un equipo de analistas financieros con muchos años de experiencia en el rubro. Nuestros clientes varían desde impatriados y expatriados a empresas e individuos localizados en los Estados Unidos. Nos enorgullece entregar un trabajo limpio, eficiente y que impulse los proyectos de nuestros clientes. Nos satisface crecer junto al cliente y ayudarlos a alcanzar y exceder sus metas. Nuestro objetivo es poder encargarnos de las operaciones necesarias que le quitan tiempo al cliente para enfocarse en hacer lo que lo hace feliz!</p>
                                    <hr class="my-5" />
                                    <h4 class="mb-4">
                                        Lo que hacemos
                                    </h4>
                                    <p>Preparación de declaraciones juradas para impatriados y expatriados alrededor del mundo. consultoría antes, durante y después del proceso. Nos encargamos de todos los asuntos relacionados con el IRS. Brindamos soluciones para tu negocio desde la incorporación hasta los impuestos y todo lo que conlleve el procedimiento entremedio. Desde tu casa, sin tener que viajar a los Estados Unidos. Diseñamos planes de inversión y ahorro de corto, mediano y largo plazo.</p>
                                    <div class="card bg-light shadow-none">
                                        <div class="card-body">
                                            <h6>Preguntas que deberías hacerte.</h6>
                                            <ul class="mb-0">
                                                <li class="text-italic">¿Estoy enfocando toda mi energía en hacer que mi negocio y finanzas crezcan? O estoy perdiendo tiempo valioso en aspectos que podrían ser resueltos por mi.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <hr class="my-5" />
                                    <h4 class="mb-4">
                                        Nuestra Experiencia
                                    </h4>
                                    <p class="lead">Hemos preparado más de 4,000 declaraciones personales (Formulario 1040) y hemos asistido a +300 empresas a crecer. Hace +8 años que comenzamos en la industria y hemos estado generando relaciones fuertes y duraderas con nuestros apreciados clientes. Nuestros servicios se han extendido a más de 15 países, en los que hemos podido brindar soluciones a clientes sin que tengan que ir a los Estados Unidos.</p>
                                   
                                    <hr class="my-5" />
                                    <h4>Algunos países en los que se encuentran nuestros clientes</h4>
                                        <p class="lead">
                                        Estados Unidos, Reino Unido, España, Grecia, Republica Checa, Chile, Uruguay, Colombia, Costa Rica, Argentina, México, Vietnam, Peru, Paraguay
                                        </p>
                                    
                                </div>
                            </div>
                        </div>
                        <Team />
                    </section>
                </main>
            </div>
            <Wpp />
            <Footer />
        </div>
    </>
    )
}

export default About;